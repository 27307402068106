/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from "axios";
import { kebabCase, snakeCase } from "lodash";

export class RestError extends Error {
  constructor(
    message: string,
    public readonly responseStatusCode: number,
    public readonly responseResult: boolean,
    public readonly responseCode: string,
    public readonly responseMessage: string,
    public readonly request: {
      headers: { [key: string]: string };
      data: { [key: string]: any };
      host: string;
      url: string;
    },
    public readonly response: any
  ) {
    super(message);
  }
}

export function extractKey<T>(object: T, key: keyof T): any {
  const extracted = object[key];

  delete object[key];

  return extracted;
}

export function guardEnvVariable(key: string): string {
  const envVar = process.env[key];

  if (!envVar) {
    throw new Error(`Environment variable [${key}] must be configured.`);
  }

  return envVar;
}

export function kebabCasedObject(data: any, delimiterForArray?: string): any {
  return typeof data === "object"
    ? Object.keys(data).reduce<{ [key: string]: any }>((p, e) => {
        p[kebabCase(e)] = delimiterForArray && Array.isArray(data[e]) ? data[e].join(delimiterForArray) : data[e];
        return p;
      }, {})
    : {};
}

export function snakeCasedObject(data: any, delimiterForArray?: string): any {
  return typeof data === "object"
    ? Object.keys(data).reduce<{ [key: string]: any }>((p, e) => {
        p[snakeCase(e)] = delimiterForArray && Array.isArray(data[e]) ? data[e].join(delimiterForArray) : data[e];
        return p;
      }, {})
    : {};
}

export class HttpRequest {
  static async request<T>(
    host: string,
    method: "POST" | "GET" | "PATCH" | "DELETE" | "PUT",
    url: string,
    data: any,
    headers: { [key: string]: string }
  ): Promise<AxiosResponse<T>> {
    try {
      if (method === "GET") return await this.get(`${host}${url}`, data, headers);
      else if (method === "POST") return await this.post(`${host}${url}`, data, headers);
      else if (method === "DELETE") return await this.delete(`${host}${url}`, data, headers);
      else if (method === "PATCH") return await this.patch(`${host}${url}`, data, headers);
      else if (method === "PUT") return await this.put(`${host}${url}`, data, headers);
      else throw new Error(`NOT SUPPORTED, HttpRequest.${method}`);
    } catch (e) {
      const err: Error & { request: any; response: any } = e as any;

      // eslint-disable-next-line no-console
      console.warn(
        `Internal API Call Error (${err?.response?.status || "Unknown"}, ${
          err?.response?.data?.message || "An error has occurred."
        } @ [${method}] ${host.split("//").slice(-1)[0].split("/").slice(1).join("/")}${url})`,
        err?.response?.data?.message,
        {
          headers,
          host,
          url,
          data,
        },
        err.response?.data
      );

      throw new RestError(
        `Internal API Call Error (${err?.response?.status || "Unknown"}, ${
          err?.response?.data?.message || "An error has occurred."
        } @ [${method}] ${host.split("//").slice(-1)[0].split("/").slice(1).join("/")}${url})`,
        err?.response?.status as number,
        err?.response?.data?.result as boolean,
        err?.response?.data?.code,
        err?.response?.data?.message,
        {
          headers,
          host,
          url,
          data,
        },
        err.response?.data
      );
    }
  }

  static async get<T>(url: string, data: any, headers: { [key: string]: string }): Promise<AxiosResponse<T>> {
    const resp = await axios.request<T>({
      method: "GET",
      url,
      headers: {
        ...kebabCasedObject(headers),
        "Content-type": typeof data === "object" ? "application/json" : undefined,
      },
      params: snakeCasedObject(data, ","),
    });

    return resp;
  }

  static async post<T>(url: string, data: any, headers: { [key: string]: string }): Promise<AxiosResponse<T>> {
    const resp = await axios.request<T>({
      method: "POST",
      url,
      headers: {
        ...kebabCasedObject(headers),
        "Content-type": typeof data === "object" ? "application/json" : undefined,
      },
      data: typeof data === "object" ? JSON.stringify(data, (_, x) => (typeof x === "bigint" ? x.toString() : x)) : data,
    });

    return resp;
  }

  static async delete<T>(url: string, data: any, headers: { [key: string]: string }): Promise<AxiosResponse<T>> {
    const resp = await axios.request<T>({
      method: "DELETE",
      url,
      headers: {
        ...kebabCasedObject(headers),
        "Content-type": typeof data === "object" ? "application/json" : undefined,
      },
      data: typeof data === "object" ? JSON.stringify(data, (_, x) => (typeof x === "bigint" ? x.toString() : x)) : data,
    });

    return resp;
  }

  static async patch<T>(url: string, data: any, headers: { [key: string]: string }): Promise<AxiosResponse<T>> {
    const resp = await axios.request<T>({
      method: "PATCH",
      url,
      headers: {
        ...kebabCasedObject(headers),
        "Content-type": typeof data === "object" ? "application/json" : undefined,
      },
      data: typeof data === "object" ? JSON.stringify(data, (_, x) => (typeof x === "bigint" ? x.toString() : x)) : data,
    });

    return resp;
  }

  static async put<T>(url: string, data: any, headers: { [key: string]: string }): Promise<AxiosResponse<T>> {
    const resp = await axios.request<T>({
      method: "PUT",
      url,
      headers: {
        ...kebabCasedObject(headers),
        "Content-type": typeof data === "object" ? "application/json" : undefined,
      },
      data: typeof data === "object" ? JSON.stringify(data, (_, x) => (typeof x === "bigint" ? x.toString() : x)) : data,
    });

    return resp;
  }
}
