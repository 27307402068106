import React, { lazy, Suspense, useEffect } from "react";
import { Global } from "@emotion/react";
import { useStore } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setDefaultTransformResponse } from "@coralblack/flax";
import { SSO } from "@pages/auth/SSO";
import { ErrorLayout } from "@pages/system/layout/Error";
import { loadTheme } from "@fluentui/react";
import { MyInfoGuard } from "./components/MyInfoGuard";
import { globalStyles } from "./globalStyles";
import { dialogIfTokenDestroyedByNewToken } from "@maven-surface/components/common/auth/TokenInvalidDialog";
import { MsaAuthRoute } from "@maven-surface/components/common/MsaAuthRoute";
import { RootState } from "@maven-rest/states";
import { classNames } from "@maven-helper/functions/Misc.function";
import "@maven-surface/assets/style/App.scss";
import "@coralblack/flax/dist/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

// NOTE: 버전 명시에 관한 정책이 없어서 임시로 APP.tsx에 변수 저장
// NOTE: CSV를 위한 버전 분기 처리
export const DOCS_VERSION = ["staging", "develop", "local"].includes(process.env.REACT_APP_STAGE || "local") ? "1.2.0.0" : "1.1.31.1";

loadTheme({
  defaultFontStyle: { fontFamily: '"Nunito", "Noto Sans KR", sans-serif', fontWeight: "400" },
  fonts: {
    xSmall: {
      fontSize: "0.69rem",
    },
    small: {
      fontSize: "0.75rem",
    },
    medium: {
      fontSize: "0.82rem",
    },
    large: {
      fontSize: "0.91rem",
    },
    xLarge: {
      fontSize: "1rem",
    },
  },
});

const HomeLayout = lazy(() => import("@layouts/home/Index"));
const SignerLayout = lazy(() => import("@layouts/signer/Index"));
const SharingLayout = lazy(() => import("@layouts/sharing/Index"));

function App() {
  const session = useStore<RootState>().getState().session;
  const appBadge = process.env.REACT_APP_BADGE;
  const appBadgeDisabled = process.env.REACT_APP_BADGE_DISABLE;

  useEffect(() => {
    document.body.classList.add("ready");

    setTimeout(() => {
      document.getElementById("spinner")?.remove();
    }, 1500);
  });

  setDefaultTransformResponse([
    function (data) {
      const o = JSON.parse(data);

      dialogIfTokenDestroyedByNewToken(o);

      return o;
    },
  ]);

  const demo = ((v) => (["local", "develop", "demo"].includes(String(process.env.REACT_APP_STAGE)) && v?.length < 30 ? v : null))(
    String(String(window.location.search || "").split("?")[1] || "")
      .split("&")
      .filter((x) => x.startsWith("demo-msg="))
      .map((x) => x.split("=")[1])[0]
  );

  return (
    <BrowserRouter>
      <Global styles={globalStyles} />
      <div
        className={classNames("app-wrapper", `badge--${String(appBadge || "unknown").toLowerCase()}`, {
          [`badge--disabled badge--${appBadgeDisabled}`]: !!appBadgeDisabled,
          "--demo": !!demo,
        })}
      >
        <div className="app-demo-message">{demo}</div>
        {!appBadgeDisabled && appBadge && (
          <div className={classNames("notranslate app-sidebar-ribbon", `--${String(appBadge).toLowerCase()}`)}>{appBadge}</div>
        )}
        <Suspense
          fallback={
            <div className="suspense-loading">
              <div className="loading">
                <div className="loader" />
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact={true} path="/404" render={ErrorLayout} />
            <Route exact={true} path="/auth" render={() => <SSO session={session} />} />
            <Route path="/signers" component={SignerLayout} />
            <Route path="/sharings" component={SharingLayout} />
            <MsaAuthRoute session={session} authStrategy="AUTHORIZED_ONLY" path="/" render={() => <MyInfoGuard component={HomeLayout} />} />
            <Redirect from="*" to="/404?r=rt" />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
